<template>
  <vca-card>
    <vca-row>
      <vca-column>
        <h3>{{ $t("deposit.submit.overview") }}</h3>
        <div>
          <vca-row>
            <span first> {{ $t("deposit.submit.reason_for_payment") }}</span>
            <span last> {{ current.reason_for_payment }}</span>
          </vca-row>
          <vca-row>
            <span first> {{ $t("deposit.submit.status") }}</span>
            <span last> {{ current.status }}</span>
          </vca-row>
          <vca-row>
            <span first> {{ $t("deposit.submit.crew") }}</span>
            <span last> {{ current.crew.name }}</span>
          </vca-row>
        </div>
      </vca-column>
      <vca-column>
        <h3>{{ $t("deposit.submit.events") }}</h3>
        <div :key="index" v-for="(unit, index) in current.deposit_units">
          <vca-row>
            <div first>
              {{ unit.taking.name }}
            </div>
            <div last>{{ formatMoney(unit.money) }}</div>
          </vca-row>
        </div>
      </vca-column>
    </vca-row>
    <button class="vca-button" @click="submit()">
      {{ $t("deposit.submit.submit") }}
    </button>
    <button class="vca-button-small" @click="close">
      {{ $t("deposit.submit.close") }}
    </button>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DepositSubmit",
  computed: {
    ...mapGetters({
      current: "deposits/current",
    }),
  },
  methods: {
    submit() {
      var update = this.current;
      update.status = "wait";
      update.money.currency =
        update.money.currency === "" ? "EUR" : update.money.currency;
      this.$store.commit("deposits/edit", update);
      this.$store.dispatch({ type: "deposits/update" }).then(() => {
        this.$emit("close");
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
